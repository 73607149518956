<template>
  <div class="lifestyle-product">
    <div v-if="mainImage" class="left-section">
      <lazy-image :item="mainImage" class="main-image"></lazy-image>
    </div>
    <div class="right-section">
      <div class="text">
        <h2 v-if="item.title">
          {{ item.title }}
        </h2>
        <p v-if="item.text">
          {{ item.text }}
        </p>
        <a v-if="item.ctaLink && item.ctaText" :href="localizedUrl + item.ctaLink">{{ item.ctaText }}</a>
      </div>
      <div class="small-container">
        <preview-button v-if="item.modalData" :modal-data="item.modalData"></preview-button>
        <lazy-image v-if="smallImage" :item="smallImage" class="small-image"></lazy-image>
      </div>
    </div>
  </div>
</template>

<script>
import ImageManager from 'src/modules/prismic/utilities/ImageManager'
import LazyImage from './utils/LazyImage.vue'
import PreviewButton from './PreviewButton.vue'
import { currentStoreView } from '@vue-storefront/core/lib/multistore'

export default {
  props: ['item'],
  name: 'LifestyleProduct',
  components: {
    LazyImage,
    PreviewButton
  },
  computed: {
    mainImage() {
      return this.item.mainImage && this.item.mainImage.url ? {
        url: this.item.mainImage.url,
        lowDefinitionUrl: ImageManager.getLowDefinition(this.item.mainImage.url, 70, 1.4285714286, false, 4),
        srcset: ImageManager.getSrcSet(this.item.mainImage.url, 1000, 200, 100, 1.4285714286),
        alt: this.item.mainImage.alt
      } : null
    },
    smallImage() {
      return this.item.smallImage && this.item.smallImage.url ? {
        url: this.item.smallImage.url,
        lowDefinitionUrl: ImageManager.getLowDefinition(this.item.smallImage.url, 70, 1.4285714286, false, 4),
        srcset: ImageManager.getSrcSet(this.item.smallImage.url, 1000, 200, 100, 1.4285714286),
        alt: this.item.smallImage.alt
      } : null
    },
    localizedUrl () {
      return currentStoreView().url || ''
    }
  }
}
</script>

<style lang="scss" scoped>
@import '~theme/css/core';

.lifestyle-product {
  display: flex;
  flex-direction: row;
  column-gap: 2rem;
  margin: 4rem 6rem;
  padding: 0;

  @include bp(max md){
    flex-direction: column;
    margin: 4rem 0;
  }

  @include bp(max lg){
    column-gap: 1rem;
  }

  .left-section {
    flex: 3;

    .main-image {
      overflow: hidden;
      width: 100%;
      height: 100%;
      aspect-ratio: 4/5;

      /deep/ img {
        object-fit: cover;
        width: 100%;
        height: 100%;
      }
    }
  }

  .right-section {
    flex: 2;
    display: flex;
    flex-direction: column;

    .text {
      flex: 1;
      padding: 2rem;
      line-height: 1.1;

      @include bp(max lg){
        padding: 1rem;
      }

      h2 {
        font-weight: $weight-regular;
        font-size: 2.5em;
        margin-bottom: 2rem;
      }

      p {
        font-size: 1.6em;
        margin-bottom: 2rem;
      }

      a {
        color: var(--zenith-black);
        text-decoration: underline;
        font-size: 1.8em;
      }
    }

    .small-container {
      position: relative;

      .small-image {
        aspect-ratio: 1/1;
        overflow: hidden;

        ::v-deep img {
          object-fit: cover;
          height: 100%;
          width: 100%;
        }
      }
    }
  }
}
</style>
